import React from "react";
import { Link } from "gatsby";
import { Layout } from "../../components";

const GoogleAds = ({ location: { pathname } }) => {
  return (
    <Layout
      seo={{
        title: "Google Ads",
        href: pathname,
        lang: "pl"
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/offer/google-ads/",
      }}
    >
      <section className="container-fluid google_ads_section">
        <div className="text-center">
          <h1>Google Ads</h1>
        </div>
        <div className="row social_row">
          <div className="col-md-10 m-auto">
            <p className="text-center">
              Kampanie Google Ads to znacząca część naszych działań
              marketingowych. Nasz konik. Dlatego jedna z naszych spółek -
              Agencja WHEN - posiada najwyższe odznaczenie przyznawane przez
              Google - Google Premier Partner i aktywnie uczestniczy w
              wydarzeniach organizowanych przez Google.
            </p>
            <p className="text-center">
              Kampanie Google Ads to najlepszy sposób dotarcia do Twoich
              klientów. Dzięki tej formie promocji zauważysz szybkie efekty w
              postaci zwiększonego ruchu na stronie internetowej, co przełoży
              się na wzrost sprzedaży w sklepie online czy na platformie
              rezerwacyjnej lub na zwiększenie zainteresowania oferowanymi przez
              Ciebie usługami.
            </p>
            <p className="text-center">
              Za sprawą Google Ads Twoje produkty i usługi będą widoczne podczas
              wyszukiwania ich w Google oraz na różnych portalach, a także w
              YouTube.
            </p>
            <div className="row">
              <div className="col-md-4">
                <div className="inner">
                  <h4>Wyszukiwarka</h4>
                  Reklamy tekstowe i reklamy produktów w wyszukiwarce.
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner">
                  <h4>Sieć</h4>
                  Reklamy graficzne, wideo i tekstowe w witrynach partnerów
                  Google i na YouTube.
                </div>
              </div>
              <div className="col-md-4">
                <div className="inner">
                  <h4>Remarketing</h4>
                  Budowanie świadomości marki i docieranie do klientów, którzy
                  już odwiedzili witrynę.
                </div>
              </div>
            </div>
            Dzięki kampaniom Google Ads możesz maksymalnie zwiększyć zasięg
            swoich kampanii reklamowych. W zależności od Twoich indywidualnych
            potrzeb i oczekiwanych efektów, oferujemy Ci:
            <ul>
              <li>
                Skuteczną reklamę tekstową oraz reklamy Twoich produktów w
                wyszukiwarce Google, z której korzysta prawie
                <strong>99%</strong> użytkowników Internetu w Polsce
                (informacyjnie:
                https://wingsbridge.pl/najpopularniejsze-wyszukiwarki-internetowe-lista/)
              </li>
              <li>
                Reklamy na stronach powiązanych tematycznie oraz w serwisie
                YouTube
              </li>
              <li>
                Reklamy graficzne pojawiające się użytkownikom o określonych
                zainteresowaniach lub zamiarach
              </li>
              <li>
                Efektywne działania z zakresu remarketingu, zachęcające do
                powrotu użytkowników, którzy już wcześniej zainteresowali się
                Twoimi produktami i usługami
              </li>
              <li>
                Kompleksową konfigurację narzędzi analitycznych (zarówno dla
                biznesów e-commerce)
              </li>
              <li>Pełną optymalizację kampanii</li>
              <li>
                Raporty i analizy, które pozwolą Ci w pełni kontrolować postęp
                naszej pracy
              </li>
            </ul>
            Na pokładzie naszej Agencji posiadamy 5 certyfikowanych i
            doświadczonych specjalistów Google Ads, a jeden z nich zostanie
            dedykowany Twojej kampanii. Lubimy dzielić się wiedzą, więc co jakiś
            czas wszystkie kampanie są sprawdzane przez pozostałych
            specjalistów, dzięki czemu kampania dla Twojego biznesu jest
            perfekcyjnie zoptymalizowana.
          </div>
        </div>
      </section>

      <section className="container-fluid section_services">
        <div className="text-center">
          <h2>Wszystkie usługi</h2>
        </div>
        <div className="row">
          <div className="col-md-2 box">
            <span>Banery reklamowe</span>
            <Link to="/oferta/banery-reklamowe/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Analityka internetowa</span>
            <Link to="/oferta/analityka-internetowa/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Social Media</span>
            <Link to="/oferta/social-media/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Linkedin Ads</span>
            <Link to="/oferta/linkedin-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Facebook Ads</span>
            <Link to="/oferta/facebook-ads/">zobacz</Link>
          </div>
          <div className="col-md-2 box">
            <span>Kampanie zagraniczne</span>
            <Link to="/oferta/kampanie-zagraniczne/">zobacz</Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default GoogleAds;
